.menu-button[data-active] {
    background-color: rgba(235, 241, 237, 1) !important;
}

.menu-button:hover {
    background-color: rgba(235, 241, 237, 1) !important;
}

.menu-list {
    max-width: 240px;
    border-radius: 12px !important;
    box-shadow: 4px 4px 20px 0px rgba(0, 0, 0, 0.04);

    box-shadow: -4px -4px 24px 0px rgba(0, 0, 0, 0.06);
    background: var(--Background-primary, rgba(255, 255, 255, 1));

    padding: 16px 24px 16px 24px;
}

.button {
    color: var(--color-text-primary) !important;
    padding: 0 !important;
}

.button:hover {
    background-color: var(--color-background-primary) !important;
}

.typography-ellipse {
    max-width: 90px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
