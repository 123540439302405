.box {
    position: absolute;
    width: 100%;
    height: max-content;
    background-color: rgba(247, 247, 247, 1);
    padding-bottom: 100px;
}

.footer-divider {
    display: flex;
    flex-direction: column;
    text-align: center;
}

.container {
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 16px;
    padding-top: 30px;
    background-color: rgba(247, 247, 247, 1);
}

.social-container {
    display: flex;
    justify-content: center;
    gap: 20px;

    padding-bottom: 15px;
}

.contact-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.container-info {
    display: flex;
    flex-direction: column;
    text-align: center;
}

.container-rules {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.info-text {
    font-size: 13px !important;
    line-height: 16px !important;
}

@media (min-width: 850px) {
    .box {
        height: 25vh;
        padding-bottom: 0;
        background-color: var(--color-background-primary);
    }

    .footer-divider {
        flex-direction: row;
        text-align: start;
        justify-content: space-between;
        padding-bottom: 33px;
        border-bottom: 1px solid rgba(236, 236, 236, 1);
    }

    .container {
        background-color: var(--color-background-primary);
        padding-left: 120px;
        padding-right: 120px;
        padding-bottom: 12px;
        padding-top: 60px;
        max-width: 1440px;
        margin: auto;
    }

    .social-container {
        gap: 8px;
        justify-content: flex-start;
        padding-bottom: 0;
    }

    .contact-container {
        gap: 46px;
        flex-direction: row;
        justify-content: space-between;
    }

    .container-info {
        flex-direction: row;
        text-align: start;
        justify-content: space-between;
        padding-bottom: 33px;
        padding-left: 120px;
        padding-right: 120px;
        padding-bottom: 60px;
        max-width: 1440px;
        margin: auto;
    }

    .container-rules {
        flex-direction: row;
        align-items: start;
        width: 35%;
    }

    .info-text {
        width: 60%;
    }
}
