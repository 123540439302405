.typography {
    text-align: center;
}

.cart-content {
    max-height: 250px;
    max-width: 100%;
    overflow-y: scroll;
}

.text-ellipsis-line {
    max-width: 35vw;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

@media (min-width: 850px) {
    .cart-content {
        max-width: initial;
    }

    .text-ellipsis-line {
        max-width: 20vw;
    }
}
