.card-min-container {
    justify-content: space-between;
    overflow-x: auto;
    gap: 8px;
}

.card-min-child {
    flex-basis: 32%;
    flex-shrink: 0;
}

.text {
    height: 200px;
}
