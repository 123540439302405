.text-container {
    width: max-content;
    padding-left: 12px;
    padding-right: 12px;
}

.typography-center {
    text-align: center;
}

.text-line-ellipsis {
    max-width: 20vw;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.slider-container {
    max-width: 100vw;
    height: 300px;
    max-height: 60vh;
}

.image-container {
    display: flex !important;
    justify-content: center;
    max-width: 100vw;
    height: 300px;
    max-height: 60vh;
}

.content-container {
    padding: 12px;
}

.arrow {
    display: none !important;
}

.container {
    position: relative;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}

.sticky-container {
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 8px;
    position: sticky;
    bottom: 1%;
}

.images-container {
    position: relative;
    margin-top: 44px;
}
.partner-name-container {
    position: absolute;
    top: 5%;
    left: 5%;
}

.address-container {
    padding-left: 10px;
    max-height: 15vh;
    overflow-y: scroll;
}

.box-image {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 260px;
}
