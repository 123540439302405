.button {
    width: 100%;
}
.icon-brand {
    height: 32px;
    border-radius: 100%;
}

.basket-item:not(:last-child) {
    border-bottom: 1px solid rgba(236, 236, 236, 1);
}

.typography-right {
    text-align: right;
}

.cart-button {
    cursor: auto;
    max-width: 10vw;
}

.cart-content {
    max-height: 200px;
    overflow-y: scroll;
}

.portal > div {
    z-index: 2000;
}

.typography-ellipse {
    max-width: 60px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
