.container {
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 10vh;
}

.nav {
    display: none;
}

.catalog-block {
    position: relative;
    padding-bottom: 25vh;
}

.container-children {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 8px;
    row-gap: 16px;
}

.button-up {
    display: none !important;
    position: fixed;
    bottom: 20px;
    right: 30px;
    z-index: 99;
    cursor: pointer;
}
.button-up-display {
    display: block !important;
}

@media (min-width: 850px) {
    .container {
        grid-template-areas: 'nav main';
        grid-template-rows: auto auto;
        grid-template-columns: 220px auto;
        column-gap: 40px;
        row-gap: 48px;
        max-width: 1440px;
        margin: auto;
        padding-right: 120px;
        padding-left: 120px;
        padding-bottom: 10px;
        background-color: var(--color-background-primary);
    }

    .nav {
        display: block;
        grid-area: nav;
        top: 180px;
        width: 240px;

        min-height: 90vh;
    }

    .main {
        grid-area: main;
    }

    .container-children {
        column-gap: none;
        row-gap: none;
        grid-template-columns: repeat(4, 1fr);
        max-width: 940px;
        gap: 12px;
    }
}
