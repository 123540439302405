.brand-container:hover {
    transform: scale(1.02);
}

.brand-container:hover > a {
    text-decoration: none;
}

.text {
    letter-spacing: -0.5px;
    max-width: 40vw;
}

.image-container {
    margin-bottom: 8px;
}

.partner-container {
    padding-bottom: 20vh;
}

.box-image {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 180px;
}

@media (min-width: 850px) {
    .text {
        letter-spacing: 2%;
        max-width: 18vw;
    }

    .partner-container {
        padding-bottom: 0;
    }

    .box-image {
        height: 211px;
    }
}
