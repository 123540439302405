.icon {
    height: 32px;
}

.icon-brand {
    height: 32px;
    border-radius: 100%;
}

.list-brand {
    max-height: 400px;
    overflow-y: scroll;
}

.item:hover {
    background-color: rgba(235, 241, 237, 1);
    color: rgba(26, 26, 26, 1);
    border-radius: 8px;
}

.item:hover > a {
    text-decoration: none;
}

.active-item {
    background-color: rgba(235, 241, 237, 1);
    color: rgba(26, 26, 26, 1);
    font-weight: 600;
    border-radius: 8px;
}

.active-item:hover > a {
    text-decoration: none;
}

.button {
    margin-bottom: 8px;
}

.brand-container {
    margin-top: 8px;
    padding: 20px 16px 0 0;
    max-height: 370px;
}

.typography-container {
    padding: 14px 16px;
}

.input-placeholder::placeholder {
    font-size: 14px;
}

.text-ellipsis {
    max-width: 160px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-clamp: 2;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
