.button:hover {
    text-decoration: none !important;
}
.icon-brand {
    height: 32px;
    border-radius: 100%;
}

.basket-item:not(:last-child) {
    border-bottom: 1px solid rgba(236, 236, 236, 1);
}

.typography {
    text-align: center;
}

.button {
    position: fixed;
    bottom: 11vh;
    width: 90vw;
    z-index: 10;
    left: 5vw;
}

.typography-width {
    width: 45%;
}

.center-button {
    width: 40%;
    align-self: center;
}

.container {
    padding-right: 16px;
    padding-left: 16px;
    padding-bottom: 15vh;
    position: relative;
}

.document-button {
    display: inline !important;
}

.text-center {
    text-align: center;
}

.gradient-background {
    background: rgba(255, 255, 255, 1);
    background: radial-gradient(ellipse at 30% 100%, rgba(255, 255, 255, 1), transparent),
        radial-gradient(ellipse at 60% 10%, rgba(255, 255, 255, 1), transparent),
        radial-gradient(circle at 30% 10%, rgba(100, 169, 129, 1), transparent),
        radial-gradient(circle at 100% 100%, rgba(100, 169, 129, 1), transparent);
    backdrop-filter: blur(100px);
}

@media (min-width: 850px) {
    .button {
        width: 100%;
        position: relative;
        bottom: auto;
        left: auto;
        z-index: 1;
    }

    .container {
        max-width: 1440px;
        margin: auto;
        padding-right: 120px;
        padding-left: 120px;
        padding-bottom: 10px;
        background-color: var(--color-background-primary);
    }
}
