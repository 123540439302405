.icon-brand {
    width: 48px;
    height: 48px;
    border-radius: 400px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.typography {
    text-align: end;
}

.name {
    max-width: 100%;
}

@media (min-width: 850px) {
    .icon-brand {
        width: 68px;
        height: 68px;
    }

    .name {
        max-width: initial;
    }
}
