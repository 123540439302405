.container {
    padding-right: 16px;
    padding-left: 16px;
    padding-bottom: 15vh;
    position: relative;
}

@media (min-width: 850px) {
    .container {
        max-width: 1440px;
        margin: auto;
        padding-right: 120px;
        padding-left: 120px;
        padding-bottom: 10px;
        background-color: var(--color-background-primary);
    }
}
