.container {
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100vw;
    padding-bottom: 0 !important;
    overflow-y: hidden;
}

.text-container {
    max-width: 90%;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
}

.header {
    text-align: center;
}

@media (min-width: 850px) {
    .container {
        padding-top: 100px;
    }
    .text-container {
        max-width: 30%;
        display: flex;
        flex-direction: column;
        text-align: center;
        align-items: center;
    }
}
