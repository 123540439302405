.nav {
    position: fixed;
    top: 140px;
    width: 240px;
}

.container {
    padding-left: 16px;
    padding-right: 16px;
}

.text {
    display: none;
}

@media (min-width: 850px) {
    .container {
        grid-template-areas: 'nav main';
        grid-template-columns: 240px auto;
        column-gap: 40px;
        max-width: 1440px;
        min-height: 60vh;
        margin: auto;
        padding-right: 120px;
        padding-left: 120px;
        padding-bottom: 0;
        background-color: var(--color-background-primary);
    }

    .nav {
        grid-area: nav;
        position: fixed;
        top: 140px;
        width: 240px;

        max-height: 90vh;
        overflow-y: scroll;
    }

    .main {
        grid-area: main;
    }

    .text {
        display: block;
    }
}
