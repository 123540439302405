.background-container {
    position: relative;
    width: 100vw;
    height: 120vh;
    background-image: url('/background.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: scroll;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.present-background {
    background: linear-gradient(89.55deg, #034d21 0.39%, #247143 55.68%, #cddbd3 155.73%, #ebf1ed 164.85%, #247143 172%);
    position: relative;
    width: 100vw;
    height: 120vh;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.present-background::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: url('/noiseEffect.png'); /* Затемнение на изображении */
    mix-blend-mode: multiply; /* Добавляем магию смешивания ✨ */
}

.spinner-content {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: fixed;
    background: white;
    padding: 8px;
    border-radius: 20px;
    width: 30vw;
}

.spinner {
    width: 50%;
}

.content {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 90%;
    height: 90%;
}

.content-description {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 90%;
    width: 80%;
}

.typography {
    width: 220px;
    margin: auto;
}

.typography-wrapper {
    background-color: rgba(235, 241, 237, 1);
    padding: 8px 12px;
    border-radius: 12px;
}

.heart-icon-container {
    position: absolute;
    left: 5%;
    bottom: 30%;
}

.qr-image {
    height: 200px;
    max-width: 200px;
    margin: auto;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

.list-container-header {
    background-color: var(--color-background-primary);
    text-align: center;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    margin-bottom: 4px;
    padding-top: 8px;
    padding-bottom: 12px;
}

.list-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--color-background-primary);
    border-bottom-right-radius: 16px;
    border-bottom-left-radius: 16px;
    padding: 20px 12px 20px 12px;
    text-align: center;
    margin-bottom: 12px;
    max-height: 70%;
}

.list-container-overflow {
    width: 90%;
    max-height: 100%;
    overflow-y: scroll;
}

.icon-container {
    border-radius: 8px;
    padding: 8px 12px;
    max-width: max-content;
    text-align: center;
    margin: auto;
    margin-bottom: 3rem;
    background-color: rgba(255, 255, 255, 1);
}

.partner-container {
    display: flex;
    align-items: center;
    margin: auto;
}

.icon-brand {
    width: 40px;
    height: 40px;
    border-radius: 400px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-right: 8px;
}

.icon-brand-modal-header {
    position: absolute;
    top: -10%;
    left: 40%;
    transform: translate(0, -10%);
    width: 60px;
    height: 60px;
    border-radius: 400px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.description-container {
    background-color: rgba(235, 241, 237, 0.8);
    box-shadow: 4px -4px 12px 0px rgba(3, 77, 33, 0.4);
    padding: 12px 16px;
    border-radius: 16px;
    border-bottom-right-radius: 0;
    width: fit-content;
    margin-left: auto;
    margin-top: auto;
    margin-bottom: 28px;
}

.text-line-ellipsis {
    max-width: 150px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.drawer-button-bottom {
    background-color: rgba(255, 255, 255, 0.52);
    border-radius: 16px 16px 0 0;
    position: fixed;
    bottom: 0;
    width: 100vw;
    padding: 22px 16px 5px 16px;
}

@media (min-width: 1023px) {
    .spinner {
        width: 20%;
    }

    .content {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 32px 50px;
        border: 1px solid rgba(236, 236, 236, 1);
        border-radius: 20px;
        width: 460px;
        height: 460px;
    }

    .list-container {
        max-height: 60%;
    }

    .content-description {
        background-color: rgba(255, 255, 255, 0.32);
        justify-content: space-between;
        border-radius: 20px;
        padding: 60px;
        height: 60%;
        width: 50%;
    }

    .heart-icon-container {
        bottom: 30%;
    }

    .description-container {
        background-color: rgba(235, 241, 237, 0.32);
        border-radius: 16px;
        border-bottom-left-radius: 0;
        width: max-content;
        max-width: 260px;
        position: absolute;
        bottom: 85%;
        left: 105%;
    }
}
