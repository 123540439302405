.button-container {
    width: 10vw;
}

.button {
    border-color: rgba(236, 236, 236, 1) !important;
}

@media (min-width: 850px) {
    .button-container {
        width: 20vw;
    }
}
