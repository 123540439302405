.container {
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 15vh;
}

.nav {
    display: none;
}

.sticky {
    position: fixed;
    top: 150px;
}

.relative {
    position: absolute;
    bottom: 0;
    top: auto;
}

.container-children {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 8px;
    row-gap: 16px;
}

.animation {
    width: 10%;
    height: 10%;
}

.catalog-block {
    padding-bottom: 25vh;
}

@media (min-width: 850px) {
    .container {
        grid-template-areas: 'nav main';
        grid-template-columns: 220px auto;
        column-gap: 40px;
        max-width: 1440px;
        margin: auto;
        min-height: 60vh;
        padding-right: 120px;
        padding-left: 120px;
        padding-bottom: 10px;
        background-color: var(--color-background-primary);
    }

    .nav {
        display: block;
        grid-area: nav;
        position: fixed;
        top: 150px;
        width: 240px;

        max-height: 70vh;
        overflow-y: scroll;
    }

    .main {
        grid-area: main;
    }

    .container-children {
        column-gap: none;
        row-gap: none;
        grid-template-columns: repeat(4, 1fr);
        max-width: 940px;
        gap: 12px;
    }

    .animation {
        width: 8%;
        height: 8%;
    }

    .mobile-navigate {
        display: none;
    }
}
