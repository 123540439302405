.full-button {
    width: 100%;
    display: flex;
}

.button-exit {
    position: absolute;
    bottom: 5%;
}

.container {
    padding-right: 16px;
    padding-left: 16px;
    padding-bottom: 15vh;
    position: relative;
}

.button-container {
    position: relative;
    height: 80vh;
}

@media (min-width: 850px) {
    .container {
        max-width: 1440px;
        margin: auto;
        padding-right: 120px;
        padding-left: 120px;
        padding-bottom: 0;
        background-color: var(--color-background-primary);
    }
}
