.shadow {
    display: none;
}

.shadow-mobile {
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 13px;
    background-color: white;
}

.desktop {
    position: relative;
    display: block;
    max-width: 1440px;
    margin: auto;
    padding-left: 120px;
    padding-right: 120px;
    padding-top: 0;
}

.sticky-header {
    display: none;
}

.menu-button[data-active] {
    background-color: rgba(235, 241, 237, 1) !important;
}

.menu-button:hover {
    background-color: rgba(235, 241, 237, 1) !important;
}

.icon-brand {
    height: 32px;
    border-radius: 100%;
}

.basket-item:not(:last-child) {
    border-bottom: 1px solid rgba(236, 236, 236, 1);
}

.button:hover > a {
    text-decoration: none;
}

.text-line-ellipsis {
    max-width: 80vw;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.mobile-header {
    display: flex;
    justify-content: space-between;
}

.sticky-header-mobile {
    position: sticky;
    background-color: white;
    position: -webkit-sticky; /* Safari */
    padding-bottom: 8px;
    top: 0;
    z-index: 100;
}

.mobile-navigate-header {
    margin-top: 8px;
    padding-top: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    position: -webkit-sticky; /* Safari */
    top: 0;
    z-index: 10;
    background-color: white;
    padding-bottom: 8px;
    padding-left: 16px;
    padding-right: 16px;
}

@media (min-width: 850px) {
    .shadow {
        display: block;
        box-shadow: 0px 17px 26px -4px rgba(1, 14, 7, 0.02);
        z-index: 10;
    }

    .shadow-mobile {
        display: none !important;
    }

    .mobile {
        display: none !important;
    }

    .sticky-header {
        display: block;
        box-shadow: 0px 17px 26px -4px rgba(1, 14, 7, 0.02);
        margin: auto;
        margin-bottom: 52px;
        z-index: 10;
        position: sticky;
        position: -webkit-sticky; /* Safari */
        background-color: white;
        top: 0;
        z-index: 10;
        max-width: 1440px;
        padding-left: 120px;
        padding-right: 120px;
        padding-top: 0;
        padding-bottom: 4px;
    }
}

.typography {
    text-align: center;
}
