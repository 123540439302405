.container-children {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 8px;
    row-gap: 16px;
}

@media (min-width: 850px) {
    .container-children {
        column-gap: none;
        row-gap: none;
        grid-template-columns: repeat(4, 1fr);
        max-width: 940px;
        gap: 12px;
    }
}
