.image-container {
    display: flex;
    justify-content: center;
    max-width: 100vw;
    height: 315px;
}

.gallery-container {
    min-height: 25vh;
    margin-bottom: 32px;
}

.box-image {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 80%;
    height: 315px;
}

.box-image-mini {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 80px;
    height: 75px;
}

.scroll-wrapper {
    height: 315px;
    width: 80px;
    overflow-y: scroll;
    border-radius: 12px;
}
