.text-ellipsis {
    max-width: 150px;
    min-height: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-clamp: 2;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.text-line-ellipsis {
    max-width: 140px;
    min-height: 20px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.max-width {
    max-width: 140px;
}

.icon-logo {
    position: absolute;
    bottom: 0;
    left: 8px;
    bottom: 8px;
    border-radius: 100%;
}

@media (min-width: 850px) {
    .text-ellipsis {
        max-width: 12vw;
    }
}
