@import '~normalize.css';

@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';

:root {
    --safe-top: env(safe-area-inset-top);
    --safe-bottom: env(safe-area-inset-bottom);
}

*,
*:after,
*:before {
    box-sizing: border-box;
}

/* Hide scrollbar for Chrome, Safari and Opera */
*::-webkit-scrollbar {
    display: none;
}

* {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

.mb-4 {
    margin-bottom: 4px;
}

.mb-8 {
    margin-bottom: 8px;
}

.mb-10 {
    margin-bottom: 10px;
}

.mb-12 {
    margin-bottom: 12px;
}

.mb-16 {
    margin-bottom: 16px;
}

.mb-20 {
    margin-bottom: 20px;
}

.mb-24 {
    margin-bottom: 24px;
}

.mb-28 {
    margin-bottom: 28px;
}

.mb-30 {
    margin-bottom: 30px;
}

.mb-32 {
    margin-bottom: 32px;
}

.mb-36 {
    margin-bottom: 36px;
}

.mb-40 {
    margin-bottom: 40px;
}

.mb-44 {
    margin-bottom: 44px;
}

.mb-48 {
    margin-bottom: 48px;
}

.mt-4 {
    margin-top: 4px;
}

.mt-8 {
    margin-top: 8px;
}

.mt-16 {
    margin-top: 16px;
}

.mt-20 {
    margin-top: 20px;
}

.mt-24 {
    margin-top: 24px;
}

.mt-28 {
    margin-top: 28px;
}

.mt-32 {
    margin-top: 32px;
}
