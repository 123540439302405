.typography-center {
    text-align: center;
}

.text-line-ellipsis {
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.typography-right {
    text-align: right;
}

.container {
    max-width: 95vw;
}

.product-item {
    grid-template-columns: 1fr 2fr 1fr;
}

.parent-container {
    padding-left: 16px;
    padding-right: 16px;
}

.nav {
    display: none;
}

@media (min-width: 850px) {
    .container {
        max-width: 43vw;
    }

    .product-item {
        grid-template-columns: 1fr 6fr 1fr;
    }

    .parent-container {
        grid-template-areas: 'nav main';
        grid-template-rows: auto auto;
        grid-template-columns: 240px auto;
        column-gap: 40px;
        max-width: 1440px;
        min-height: 60vh;
        margin: auto;
        padding-right: 120px;
        padding-left: 120px;
        padding-bottom: 0;
        background-color: var(--color-background-primary);
    }

    .nav {
        display: block;
        position: fixed;
        top: 140px;
        width: 240px;
    }

    .main {
        grid-area: main;
    }
}
