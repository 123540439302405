.typography-center {
    text-align: center;
}

.typography-start {
    text-align: start;
}

.button {
    width: 100%;
}

.animation-confetti {
    position: absolute;
    pointer-events: none;
    width: 100%;
    height: 100%;
    top: -30%;
}

.animation-arrow {
    pointer-events: none;
    position: absolute;
    width: 80%;
    height: 15vh;
    top: 22%;
    right: -20%;
    transform: rotateY(120deg) rotate(30deg);
}

.container {
    padding-right: 16px;
    padding-left: 16px;
    padding-bottom: 15vh;
    position: relative;
}

.desktop-container {
    display: none;
}

@media (min-width: 850px) {
    .animation-confetti {
        left: 0%;
        top: -30%;
    }
    .animation-arrow {
        transform: rotateY(130deg) rotate(30deg);
        height: 20vh;
        top: 35%;
        right: -12%;
    }

    .container {
        max-width: 1440px;
        margin: auto;
        padding-right: 120px;
        padding-left: 120px;
        padding-bottom: 0;
        background-color: var(--color-background-primary);
    }

    .desktop-container {
        display: block;
    }
}
