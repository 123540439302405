.desktop {
    display: none;
}

.button {
    padding-left: 0 !important;
}

@media (min-width: 850px) {
    .desktop {
        display: block;
    }
}
