.button {
    width: 100%;
}

.typography-center {
    padding-left: 8px;
    padding-right: 8px;
    text-align: center;
    background-color: var(--color-background-primary);
}

.document-button {
    display: inline !important;
}

.input:-webkit-autofill,
.input:-webkit-autofill:hover,
.input:-webkit-autofill:focus,
.input:-webkit-autofill:active {
    -webkit-background-clip: text;
    -webkit-text-fill-color: rgba(79, 79, 79, 1);
    transition: background-color 5000s ease-in-out 0s;
}
