.icon {
    align-self: center;
}

.container {
    position: relative;
}

.text {
    color: rgba(64, 63, 63, 1);
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.5px;
}

.text-ellipsis {
    max-width: 60px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.sticky-category {
    position: sticky;
    position: -webkit-sticky; /* Safari */
    top: 40px;
    z-index: 10;
    background-color: white;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 4px;
}

@media (min-width: 850px) {
    .sticky-category {
        display: none;
    }

    .mobile-navigate {
        display: none;
    }
}
